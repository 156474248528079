import React from 'react';
import ServiceList from '../../containers/services/service-list/ServiceList';

import './ServicesPage.css';
const ServicesPage = () => {
  return (
    <div className="service-list-page">
      <div>
        <h1>Services We Render</h1>
      </div>
      <br />
      <ServiceList />
    </div>
  );
};

export default ServicesPage;
