import React, { Fragment } from 'react';

import { IconButton } from '@mui/material';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const Hamburger = ({ className, handleHamburger }) => {
  return (
    <Fragment>
      <IconButton onClick={handleHamburger} className={className}>
        <MenuOutlinedIcon />
      </IconButton>
    </Fragment>
  );
};

export default Hamburger;
