import React, { useState } from 'react';
import Constants from '../constants/Constants';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import ContactUs from '../containers/contact-us-links/ContactUs';
import Hamburger from '../containers/hamburger/Hamburger';

import './Navigation.css';

const Navigation = () => {
  const { COMPANY_NAME, SERVICES_TITLE } = Constants;

  const [isDropDownActive, setIsDropDownActive] = useState(false);

  const handleHamburger = () => {
    if (!isDropDownActive) {
      setIsDropDownActive(true);
    } else {
      setIsDropDownActive(false);
    }
  };

  return (
    <div className="nav-section">
      <nav className="nav-container">
        <div className="home-page-link">
          <Button component={Link} to="/">
            {COMPANY_NAME}
          </Button>
          <Button
            component={Link}
            to="/services"
            className="services-page-link"
          >
            {SERVICES_TITLE}
          </Button>
        </div>
        <div className="hamburger">
          <Hamburger
            className="hamburger-btn"
            handleHamburger={handleHamburger}
          />
        </div>
        <ContactUs isDropDownActive={isDropDownActive} />
      </nav>
    </div>
  );
};

export default Navigation;
