/* eslint-disable import/no-anonymous-default-export */
export default {
  //Nav bar
  HOME_TITLE: 'Home',
  COMPANY_NAME: '234Detail',
  SERVICES_TITLE: 'Services',
  TELEPHONE_NO: '1(972) 217-2371',
  EMAIL: 'EMAIL',
  EMAIL_LINK: '234detail@gmail.com',
  INSTAGRAM: 'Instagram',
  INSTAGRAM_LINK: 'https://www.instagram.com/234detail/?igshid=YmMyMTA2M2Y%3D',
  TIKTOK: 'TikTok',
  TIKTOK_LINK: 'https://www.tiktok.com/@234detail?_t=8WocvRuEgRC&_r=1',
  TWITTER: 'Twitter',
  TWITTER_LINK: 'https://twitter.com/234detail?s=11',

  //Views

  TABLET_VIEW: '754',
  PHONE_VIEW: '500',
  MOBILE_VIEW: '750',

  //Packages Page
  INCLUDES: ' Includes',
  SERVICES_PAGE_HEADING: 'Services We Render',
};
