const services = [
  {
    id: 'qd001',
    title: 'Quick Detail',
    prices: [
      {
        id: 'qd001se001',
        label: 'Sedan',
        value: 'Sedan',
        price: '60',
      },
      {
        id: 'qd001su001',
        label: 'SUV',
        value: 'SUV',
        price: '70',
      },
    ],
    serviceDetail: [
      'Foam Bath',
      'Bucket Wash',
      'Wheels Cleaned',
      'Tires Cleaned and Conditioned',
      'Interior Vacuum',
      'Interior Wipe Down',
      'Door panels, Door Jams and windows cleaned',
    ],
  },
  {
    id: 'ed001',
    title: 'Exterior Detail',
    prices: [
      {
        id: 'ed001se001',
        label: 'Sedan',
        value: 'Sedan',
        price: '110',
      },
      {
        id: 'ed001su001',
        label: 'SUV',
        value: 'SUV',
        price: '125',
      },
    ],
    serviceDetail: [
      'Foam Bath',
      'Bucket Wash',
      'Engine Detail',
      'Clay Bar Treatment',
      'Iron Removal Treatment',
      '6 Month Ceramic Sealant',
      'Wheels Deep Cleaned',
      'Tires and Trim Cleaned and Conditioned',
    ],
  },
  {
    id: 'id001',
    title: 'Interior Detail',
    prices: [
      {
        id: 'id001se001',
        label: 'Sedan',
        value: 'Sedan',
        price: '150',
      },
      {
        id: 'id001su001',
        label: 'SUV',
        value: 'SUV',
        price: '170',
      },
    ],
    serviceDetail: [
      'Thorough vacuum all nooks and crannies',
      'Windows and Door Jambs Cleaned',
      'Clean all Interior Carpets and Floor Mats',
      'Dash, Doors, AC and Cup Holders Wiped Down.',
      'Door Panels, Dash, Glove compartment and Console properly Cleaned and Conditioned.',
      'Heated Extraction on fabric seats and Floor - if applicable',
      'Clean and Condition leather seats - if applicable',
    ],
  },
  {
    id: 'fd001',
    title: 'Full Detail',
    prices: [
      {
        id: 'fd001se001',
        label: 'Sedan',
        value: 'Sedan',
        price: '250',
      },
      {
        id: 'fd001su001',
        label: 'SUV',
        value: 'SUV',
        price: '290',
      },
    ],
    serviceDetail: [
      'Foam Bath',
      'Bucket Wash',
      'Engine Detail',
      'Clay Bar Treatment',
      'Iron Removal Treatment',
      '6 Month Ceramic Sealant',
      'Wheels Deep Cleaned',
      'Tires and Trim Cleaned and Conditioned',
      'Windows and Door Jambs Cleaned',
      'Interior Vacuum',
      'Dash, Doors, AC and Cup Holders Wiped Down.',
    ],
  },

  // {
  //   id: 'pc001',
  //   title: 'Paint Correction (2 steps)',
  //   prices: [
  //     {
  //       id: 'pc001se001,
  //       label: 'Sedan',
  //       value: 'Sedan',
  //       price: '400',
  //     },
  //     {
  //       id: 'pc001su001,
  //       label: 'SUV',
  //       value: 'SUV',
  //       price: '500',
  //     },
  //   ],
  //   serviceDetail: [
  //     'Quick interior detailing',
  //     'Paint enhancement process which removes light swirls, all acid rain, water spots on paint and creates a high gloss',
  //     '6 - 8 Month Paint Sealant',
  //   ],
  // },
  // {
  //   id: 'pc002',
  //   title: 'Paint Correction + Ceramic Coating',
  //   prices: [
  //     {
  //       id: 'pc002se001,
  //       label: 'Sedan',
  //       value: 'Sedan',
  //       price: '650',
  //     },
  //     {
  //       id: 'pc002su001,
  //       label: 'SUV',
  //       value: 'SUV',
  //       price: '800',
  //     },
  //   ],
  //   serviceDetail: [
  //     'Interior Detail ',
  //     'Paint enhancement process which removes light swirls, all acid rain, water spots on paint and creates a high gloss',
  //     '1.5 Year Ceramic Coating',
  //   ],
  // }
];

export default services;
