import React from 'react';
import { Box, Divider } from '@mui/material';
import ServiceItemDetail from '../service-item-detail/ServiceItemDetail';

import './ServiceItem.css';
import ServicePrices from '../service-prices/ServicePrices';

const ServiceItem = (props) => {
  const { id, title, prices, serviceDetail } = props;

  const divider = <Divider orientation="vertical" variant="middle" flexItem />;

  return (
    <div className="service-item-container">
      <div className="service-item-heading">
        <h3 className="service-title">{title}</h3>
      </div>
      <div className="service-item-prices">
        <Box className="service-item-price">
          {prices.map((packagePrice) => {
            return (
              <>
                <ServicePrices key={packagePrice.id} {...packagePrice} />
                {packagePrice.id === 1 && divider}
              </>
            );
          })}
        </Box>
      </div>

      <p className="service-include">Includes:</p>

      <div className="service-entity">
        <ul>
          {serviceDetail.map((item) => {
            return <ServiceItemDetail key={id} item={item} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default ServiceItem;
