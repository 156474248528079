import React from 'react';

import Constants from '../../constants/Constants';

import SocialMedia from '../../customs/social-media/SocialMedia';
import { Button, List, ListItem } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

import Email from '../../customs/malito-email/Email';
import InstagramIcon from '../../customs/icons/InstagramIcon';
import TiktokIcon from '../../customs/icons/TiktokIcon';
// import TwitterIcon from '../../customs/icons/TwitterIcon';

const ContactUs = ({ isDropDownActive }) => {
  const {
    EMAIL,
    EMAIL_LINK,
    TELEPHONE_NO,
    INSTAGRAM,
    INSTAGRAM_LINK,
    TIKTOK,
    TIKTOK_LINK,
    // TWITTER,
    // TWITTER_LINK,
  } = Constants;

  return (
    <div
      className={
        isDropDownActive ? ' contact-us-links active' : 'contact-us-links'
      }
    >
      <List>
        <ListItem className="telephone-link">
          <Button
            startIcon={<CallIcon />}
            className="telephone-link"
            style={{ minWidth: '150px' }}
          >
            {TELEPHONE_NO}
          </Button>
        </ListItem>

        <ListItem className="email-link">
          <Email
            icon={<MailOutlinedIcon />}
            title={EMAIL}
            email={EMAIL_LINK}
            subject="Inquiry"
          />
        </ListItem>

        <ListItem className="instagram-link">
          <SocialMedia
            icon={<InstagramIcon />}
            title={INSTAGRAM}
            link={INSTAGRAM_LINK}
            target="_blank"
          />
        </ListItem>

        <ListItem className="tiktok-link">
          <SocialMedia
            icon={<TiktokIcon />}
            title={TIKTOK}
            link={TIKTOK_LINK}
            target="_blank"
          />
        </ListItem>

        {/* <ListItem className="telephone-link">
          <SocialMedia
            icon={<TwitterIcon />}
            title={TWITTER}
            link={TWITTER_LINK}
            target="_blank"
            className="twitter-link"
          />
        </ListItem> */}
      </List>
    </div>
  );
};

export default ContactUs;
