import { Button } from '@mui/material';
import React from 'react';

const Email = ({ icon, title, className, email, subject, body }) => {
  return (
    <>
      <Button
        startIcon={icon}
        href={`mailto:${email}?subject=${subject || ''}&body=${body || ''}`}
        className={className}
      >
        {title}
      </Button>
    </>
  );
};

export default Email;
