import { Button } from '@mui/material';
import React from 'react';

const SocialMedia = ({ icon, title, link, target, className }) => {
  return (
    <>
      <Button
        startIcon={icon}
        href={link}
        target={target}
        className={className}
      >
        {title}
      </Button>
    </>
  );
};

export default SocialMedia;
