import React from 'react';

import './ServiceItemDetail.css';

const ServiceItemDetail = (props) => {
  const { item } = props;
  return (
    <div>
      <li className="service-detail-item" variant="body2">
        {item}
      </li>
    </div>
  );
};

export default ServiceItemDetail;
