import React from 'react';
import { Typography } from '@mui/material';

import './ServicePrices.css';

const ServicePrices = (props) => {
  const { id, label, price } = props;
  return (
    <div id={id}>
      <Typography className="vehicle-type" variant="span">
        {label}
      </Typography>
      <Typography className="vehicle-price" variant="h6">
        ${price}
      </Typography>
    </div>
  );
};

export default ServicePrices;
