import React from 'react';

import './HomePage.css';

const HomePage = () => {
  return (
    <div className="home-page-container">
      <div className="top-screen">
        <h1> 234Detail </h1>
        <h3> Your Next Door Detailer</h3>
      </div>
      <div className="bottom-screen">
        <div>
          <h1>Why Choose us to detail your vehicles?</h1>
          <div>
            <h3>#1 Rated Detailing Service</h3>
            <p>
              Our objective is to transform your vehicle back to how you picked
              it up from the dealership
            </p>
            <p> by providing unmatched service and quality.</p>
          </div>
          <div>
            <h3>Mobile Interior / Exterior Detailing</h3>
            <p>
              Hand-clean your vehicle's exterior / interior to make it look
              like-new.
            </p>
          </div>
          <div>
            <h3>Location Service</h3>
            <p>
              You do not need to come to us , WE COME TO YOU!!!. Your home,
              apartment or business
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
