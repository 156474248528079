import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment } from 'react';

import services from '../../../data/services';
import ServiceItem from '../service-item/ServiceItem';

import './ServiceList.css';

const ServiceList = () => {
  return (
    <Fragment>
      <Box className="service-prices-info">
        <Typography className="prices-info" variant="span">
          Prices are subject to change depending if vehicle(s) are in bad
          condition
        </Typography>
      </Box>
      <div className="service-list">
        {services.map((serviceItem) => {
          return <ServiceItem key={serviceItem.id} {...serviceItem} />;
        })}
      </div>
    </Fragment>
  );
};

export default ServiceList;
