import { Route, Routes } from 'react-router-dom';
import HomePage from './components/home-page/HomePage';
import ServicesPage from './components/services-page/ServicesPage';

import Navigation from './navigation/Navigation';

import './App.css';

function App() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services">
          <Route index element={<ServicesPage />} />
          {/* <Route path=":id" element={<h1>Quick Detail</h1>} /> */}
        </Route>
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </>
  );
}

export default App;
